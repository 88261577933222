import { useTranslation } from 'react-i18next';

export default function ErrorNotFoundScreen() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center h-screen mt-32">
      <div className="flex flex-row">
        <h1 className="font-inter font-bold text-3xl text-red-600">
          {t('error.notFound.code')}
        </h1>
        <h1 className="font-inter font-bold text-3xl text-white ml-4">
          {t('error.notFound.title')}
        </h1>
      </div>
      <h1 className="font-inter font-normal text-2xl text-white">
        {t('error.notFound.subtitle')}
      </h1>
    </div>
  );
}
